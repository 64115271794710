import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { RootState, TiktokState } from 'src/dashboard/types';
import { TiktokAccountIdItem } from './type';
import { setLocalTiktokShopAccountId } from 'src/utils/adHelper';


const initialState: TiktokState = {
  shopAccountIdIOptions: [],
};

/** 获取selectTiktokShopAccountId */
export const getSelectTiktokShopAccountId = (state: RootState) => state.tiktok.selectShopAccountId;
export const getTiktokShopAccountIdIOptions = (state: RootState) => state.tiktok.shopAccountIdIOptions;


const resetModalAction = createAction('tiktok/reset-action');

export const tiktokSlice = createSlice({
  name: 'fbAdvert',
  initialState,
  reducers: {
    updateSelectTiktokShopAccountId: (state, action: PayloadAction<string>) => {
      setLocalTiktokShopAccountId(action.payload);
      state.selectShopAccountId = action.payload;
    },
    updateShopTiktokAccountIdIOptions: (state, action: PayloadAction<TiktokAccountIdItem[]>) => {
      state.shopAccountIdIOptions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
    .addCase(resetModalAction, () => initialState)
  }
})

export const {
  updateSelectTiktokShopAccountId,
  updateShopTiktokAccountIdIOptions,
} = tiktokSlice.actions;

export { resetModalAction };

export default tiktokSlice.reducer;
