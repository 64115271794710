import { allHttpRequest } from "src/utils/httpRequest";
import { ConditionConfig } from "../Adtopic/component/RightContent/AutoRule/RuleDetail/Condition/type";
import {
  CampaignRequest, CpoyFbCampaignsParams, CreateDatawakeRuleItem, CreateRuleItem, DSLModel, FbDatawakeInsightListRequest,
  FbMetaAndInsightParams, ModifyFbObjectParams, ModifyFbObjectTrusteeshipParams, PaginationRequest, RuleItem, RuleLogRequest,
  UpdateDatawakeRuleItem, BatchUpdateStatusParams
} from "./type";

/** 获取条件相关配置 */
export const getFbConditionConfig = async (): Promise<ConditionConfig> => {
  const res = await allHttpRequest.post('/llm_server/fb/config/condition');
  return res.json as ConditionConfig;
}

/** 创建规则 */
export const createFbRuleHttp = async (ruleContent: CreateRuleItem) => {
  const res = await allHttpRequest.post('/llm_server/fb/ad/rule/create', ruleContent);
  return res.json;
}

/** 创建datawake规则 */
export const createFbDatawakeRuleHttp = async (ruleContent: CreateDatawakeRuleItem) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/ad/rule/create', ruleContent);
  return res.json;
}

/** 获取规则列表 */
export const getFbRuleDataHttp = async (data: PaginationRequest) => {
  const res = await allHttpRequest.post('/llm_server/fb/list/rule', data);
  return res.json;
}

/** 获取datawake规则列表 */
export const getFbDatawakeRuleDataHttp = async (data: PaginationRequest) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/list/rule', data);
  return res.json;
}

/** 删除规则 */
export const deleteFbRuleHttp = async (id: number) => {
  const res = await allHttpRequest.post(`/llm_server/fb/ad/rule/delete/${id}`);
  return res.json;
};

/** 删除datawake规则 */
export const deleteDatawakeFbRuleHttp = async (id: number) => {
  const res = await allHttpRequest.post(`/llm_server/fb/datawake/ad/rule/delete/${id}`);
  return res.json;
};

/** 获取单条规则信息 by id */
export const getRuleDataById = async (id: number) => {
  const res = await allHttpRequest.get(`/llm_server/fb/list/rule/${id}`);
  return res.json;
};

/** 获取单条datawake规则信息 by id */
export const getDatawakeRuleDataById = async (id: number) => {
  const res = await allHttpRequest.get(`/llm_server/fb/datawake/list/rule/${id}`);
  return res.json;
};

/** 更新规则 */
export const updateFbRuleHttp = async (ruleContent: RuleItem) => {
  const res = await allHttpRequest.post('/llm_server/fb/ad/rule/update', ruleContent);
  return res.json;
}

/** 更新datawake规则 */
export const updateDatawakeFbRuleHttp = async (ruleContent: UpdateDatawakeRuleItem) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/ad/rule/update', ruleContent);
  return res.json;
}

/** 获取广告系列列表 */
export const getFbCampaignList = async (data: CampaignRequest) => {
  const res = await allHttpRequest.post('/llm_server/fb/list/campaign', data);
  return res.json;
};

/** 获取日志列表 */
export const getFbLogList = async (data: RuleLogRequest) => {
  const res = await allHttpRequest.post('/llm_server/fb/list/rule_history', data);
  return res.json;
};

/** 获取账户广告成效数据 */
export const getFbAccountInsights = async () => {
  const res = await allHttpRequest.post('/llm_server/fb/insights/account');
  return res.json;
}

/** 获取账户ShopAccountId信息 */
export const getShopAccountIdData = async () => {
  const res = await allHttpRequest.get('/llm_server/fb/ad/rule/fb_shop_account');
  return res.json;
}

/** 获取dsl配置 */
export const requestDslConfig = async () => {
  const res = await allHttpRequest.post('/llm_server/fb/config/dsl');
  return res.json as DSLModel;
};

/** 获取datawake数据分析列表 */
export const getFbDatawakeInsightListHttp = async (data: FbDatawakeInsightListRequest) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/list/insight', data);
  return res.json;
}

/** 更新广告系列广告组状态 */
export const modifyFbObjectById = async (data: ModifyFbObjectParams) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/modify_fb_object_by_id', data);
  return res.json;
}

/** 更新广告系列托管状态 */
export const modifyFbObjectTrusteeship = async (data: ModifyFbObjectTrusteeshipParams) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/disable_monitoring', data);
  return res.json;
}

/** 复制广告系列 */
export const copyFbCampaigns = async (data: CpoyFbCampaignsParams) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/async_copy_fb_campaigns', data);
  return res.json;
};

/** 竞选广告 */
export const requestRunForFbAds = async (campaignIds: string[]) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/run_for_ads', {
    campaign_ids: campaignIds
  });
  return res.json;
};

/** 开启广告复制任务 */
export const startCopyFbAds = async (data: CpoyFbCampaignsParams) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/start_copy_fb', data);
  return res.json;
};

/** 获取最新的异步任务信息 */
export const getLastAsyncTask = async () => {
  const res = await allHttpRequest.get('/llm_server/fb/datawake/last_async_task');
  return res.json;
}

/** 获取当前用户的Facebook授权信息 */
export const getFbMeInfo = async () => {
  const res = await allHttpRequest.get('/llm_server/fb/datawake/get_me_info');
  return res.json;
}

/** 获取广告系列或广告组数据 */
export const getFbMetaAndInsight = async (data: FbMetaAndInsightParams) => {
  const res = await allHttpRequest.post('/llm_server/fb/datawake/get_fb_meta_and_insight', data);
  return res.json;
}

/** 批量删除接口 */
export const batchAdsDelete = async (ids: string[]) => {
  const res = await allHttpRequest.post('/llm_server/fb/batch/ads_delete', {
    ids
  });
  return res.json;
};

/** 批量更新状态接口 */
export const batchUpdateStatus = async (data: BatchUpdateStatusParams) => {
  const res = await allHttpRequest.post('/llm_server/fb/batch/ads_update', data);
  return res.json;
};
